import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) {}

  // Метод для обновления accessToken с помощью refreshToken
  refreshToken(refreshToken: string): Observable<any> {
    const body = { grant_type: 'refresh_token', refresh_token: refreshToken };
    return this.http.post<any>(`https://securetoken.googleapis.com/v1/token?key=${environment.apiKey}`, body);
  }
}
