<div class="layout-container" [ngClass]="containerClass">
    <app-sidebar></app-sidebar>
    <div class="layout-content-wrapper" [ngStyle]="beck_image ? {
  'background-image': 'url(assets/images/blog/home.jpeg)',
  'background-repeat': 'no-repeat',
  'background-size': '100% 100%'
} : {}">
        <app-topbar></app-topbar>
        <app-breadcrumb class="content-breadcrumb"></app-breadcrumb>
        <div class="layout-content" >
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-profilemenu></app-profilemenu>
    <app-config *ngIf="role === 'admin'" ></app-config>
    <div class="layout-mask"></div>
</div>
