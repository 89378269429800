import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {catchError, Observable, switchMap, throwError} from 'rxjs';

import {Router} from "@angular/router";
import {select, Store} from "@ngrx/store";

import {PersistanceService} from "./persistance.service";
import {IsLoginSelector} from "../../login/store/selectors";

import {AuthService} from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  isLogined$ : Observable<boolean | null>;
  constructor( private persServ: PersistanceService , private  router: Router, private  store: Store, private authService: AuthService) {
    this.isLogined$ = this.store.pipe(select(IsLoginSelector));

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.persServ.get('accessToken');
    const refreshToken = this.persServ.get('refreshToken');
 if(accessToken ) {

  // const userToken=this.persServ.get('accessToken');
   request = request.clone(
     {
      setParams: {
         auth: accessToken//Authorization
       }
      /* setHeaders: {
         Authorization: `Bearer ${userToken}`
       //  X-Goog-User-Project: 'your-project'
       }*/
      // headers: request.headers.set('Authorization', 'Bearer ' + this.persServ.get('accessToken'))

     });

   return next.handle(request).pipe(
     catchError((error: HttpErrorResponse) => {

           // this.auth.logOut();

           if (error.status === 401 && refreshToken) {
             return this.authService.refreshToken(refreshToken).pipe(
               switchMap((response: any) => {
                 // Сохраняем новые токены в localStorage
                 this.persServ.set('accessToken', response.access_token);
                 this.persServ.set('refreshToken', response.refresh_token);

                 // Повторяем исходный запрос с обновленным токеном
                 // Повторяем исходный запрос с обновленным токеном
                 const clonedRequest  = request.clone(
                   {
                     setParams: {
                       auth: response.access_token//Authorization
                     }
                   });

                 return next.handle(clonedRequest);
               }),

                   catchError((refreshError: HttpErrorResponse) => {
                   // Если обновление токена не удалось, выполняем логаут
                   this.router.navigate(['/'], {
                     queryParams: { LracacToken: true }
                   });
                   return throwError(refreshError);
                 })
               );
               }

             // В случае другой ошибки (не 401) пробрасываем ее дальше
             return throwError(error);
           })
         );
         } else {
           // Если токен отсутствует, просто передаем запрос без авторизации
           return next.handle(request);
         }
     }

}
