import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

import {map, mergeMap, Observable, take} from "rxjs";


import {environment} from "../../../../environments/environment";
import {LoginRequestInterface} from "../types/LoginRequestInterface";
import {CurrentUserInterface, CurrentUserInfoInterface} from "../../shared/types/CurrentUser";
import {PersistanceService} from "../../shared/services/persistance.service";
import {GetUserRequestInterface, User} from "../types/GetUserRequestInterface";



@Injectable()
export class LoginService {
  constructor(private http: HttpClient, private persServ: PersistanceService) {
  }


  LogIn(user: LoginRequestInterface): Observable<CurrentUserInterface> {
    return this.http.post<CurrentUserInterface>(`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${environment.apiKey}`, user)
  }


  GetUserInfo(email: string): Observable<CurrentUserInfoInterface | null> {

    return this.http.get<any>(`${environment.FbUrl}/UserList.json?orderBy="email"&equalTo="${email}"`).pipe(
      map((response: {[key: string]: CurrentUserInfoInterface}) => {
        return Object
          .keys(response)
          .map(key => ({
            name: response[key].name,
            email: response[key].email,
            avatar: response[key].avatar,
            mc: response[key].mc,
            role: response[key].role,
            americanName: response[key].americanName,
            additionalInfo: response[key].additionalInfo,
            phoneNumber: response[key].phoneNumber,
            officePhoneNumber: response[key].officePhoneNumber,
            officeEmail: response[key].officeEmail,
            ext: response[key].ext,
            firmName:response[key].firmName,
            firmAddress: response[key].firmAddress,
            accountantEmail: response[key].accountantEmail,
            id: key,
            // date: new Date(response[key].date)
          }))
      }),
      map((userInfo:CurrentUserInfoInterface[])=>
      {
        //const info =userInfo.find(user=> user.email==email)
       // return info? info: null
        return userInfo?userInfo[0]: null
      })
    )
  }

  GetUserListInfo(): Observable<CurrentUserInfoInterface[] > {

    return this.http.get<any>(`${environment.FbUrl}/UserList.json`).pipe(
      map((response: {[key: string]: CurrentUserInfoInterface}) => {
        return Object
          .keys(response)
          .map(key => ({
            name: response[key].name,
            email: response[key].email,
            avatar: response[key].avatar,
            mc: response[key].mc,
            role: response[key].role,
            americanName: response[key].americanName,
            additionalInfo: response[key].additionalInfo,
            phoneNumber: response[key].phoneNumber,
            officePhoneNumber: response[key].officePhoneNumber,
            officeEmail: response[key].officeEmail,
            ext: response[key].ext,
            firmName:response[key].firmName,
            firmAddress: response[key].firmAddress,
            accountantEmail: response[key].accountantEmail,
            id: key,
            // date: new Date(response[key].date)
          }))
      })
    )
  }

  GetCurrentUser(): Observable<CurrentUserInterface>  //
  {
    const idToken = {
      idToken: this.persServ.get('accessToken')
    };

    return this.http.post<GetUserRequestInterface>(`https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=${environment.apiKey}`,
      idToken
    ).pipe(

      mergeMap((res: GetUserRequestInterface) => res.users),
      take(1),


      map((response: User) => {
          return {
            displayName: "",
            // kind: '',
            email: response.email,
            localId: response.localId,
            idToken: idToken.idToken,
            registered: true
          }
        }
      )
    )
  }
}
